// ** Icon imports
import {
  Compass,
  Truck,
  ShoppingBag,
  Tag,
  CreditCard,
  Map,
  PenTool,
  Settings,
  HelpCircle,
  ShoppingCart,
  Star,
  Link,
  Droplet,
  Zap,
  Package
} from 'react-feather'

// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'
import { t } from 'i18next'

const navigation = (floCartSettingsEnabled: boolean, isOnboardingComplete: boolean): VerticalNavItemsType => {
  return [
    {
      title: t('overview'),
      icon: Compass,
      path: '/overview',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_OVERVIEW'
    },
    {
      title: t('orders'),
      icon: Truck,
      path: '/orders',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_ORDERS'
    },
    {
      title: t('abandoned_checkouts'),
      icon: Package,
      path: '/abandoned-checkouts',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_ABANDONED_CHECKOUTS'
    },
    {
      title: t('upsell'),
      icon: ShoppingBag,
      path: '/upsell',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_UPSELL'
    },
    {
      title: t('discounts'),
      icon: Tag,
      path: '/discounts',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_DISCOUNTS'
    },
    {
      title: t('automations'),
      icon: Zap,
      path: '/automations',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_AUTOMATIONS'
    },
    {
      title: t('payment_offers'),
      icon: CreditCard,
      path: '/payment-offers',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_PAYMENT_OFFERS'
    },
    {
      title: t('rewards'),
      icon: Star,
      path: '/rewards',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_REWARDS'
    },
    {
      title: t('redeem_rewards'),
      icon: Star,
      path: '/redeem-rewards',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_REDEEM_REWARDS'
    },
    {
      title: t('shipping'),
      icon: Map,
      path: '/shipping',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_SHIPPING_DELIVERY'
    },
    {
      title: t('shipping_zones'),
      icon: Map,
      path: '/shipping-delivery',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_SHIPPING_DELIVERY_V2'
    },
    {
      title: t('rto_suite'),
      icon: Droplet,
      path: '/rto-suite',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_RTO_SUITE'
    },
    {
      title: t('customize_ui'),
      icon: PenTool,
      path: '/style',
      disabled: !isOnboardingComplete,
      rbacPermission: 'TAB_CUSTOMISE_UI'
    },
    {
      title: t('settings'),
      icon: Settings,
      path: '/settings',
      rbacPermission: 'TAB_SETTINGS'
    },
    {
      title: t('support'),
      icon: HelpCircle,
      path: '/support',
      rbacPermission: 'TAB_SUPPORT'
    },
    {
      title: t('partners'),
      icon: Link,
      path: '/partners',
      rbacPermission: 'TAB_PARTNERS'
    },
    {
      sectionTitle: 'APPS'
    },
    {
      title: t('cart'),
      icon: ShoppingCart,
      path: '/shopflo-cart',
      disabled: !floCartSettingsEnabled,
      rbacPermission: 'TAB_CART'
    }
  ]
}

export default navigation
