import { t } from 'i18next'
import { getRequest } from '../core/api-client'
import { ValidationSchema } from '../hooks/useForm'
import {
  OnboardingStatusType,
  OnboardingMapper,
  GSTRegistrationType,
  PlatformsMapper,
  PlatformsType
} from '../types/onboarding'
import { apiURI } from './constants'
import { formatZip } from './formatters'
import { isNumeric } from './validations'

/**
 * Returns active store from the list of stores
 *
 * @param  {any} data       The list of stores from response
 *
 * @return  {object}     Returns active store
 */
export const getActiveStore = (data: any) => {
  if (!Array.isArray(data) || !Boolean(data.length)) return
  const activeStore = data.find((store: any) => Boolean(store?.logged_in))

  return activeStore ?? data[0]
}

/**
 * Returns mapping for onboarding steps
 *
 * @return  {OnboardingMapper}     Returns mapping
 */
export const onboardingMapper: OnboardingMapper = {
  IN_PROGRESS: {
    redirect: '/onboarding#shop-details',
    next: 'DOMAIN_VERIFIED',
    prev: null,
    onboardingCompletionRate: 16.66,
    backEnabled: false
  },
  CONNECTED: {
    next: null,
    prev: 'INTEGRATION_SETUP',
    redirect: '/settings',
    onboardingCompletionRate: 100,
    backEnabled: false
  },
  INTEGRATION_SETUP: {
    next: 'CONNECTED',
    prev: 'PAYMENT_METHOD_SETUP',
    redirect: '/onboarding#success',
    onboardingCompletionRate: 100,
    backEnabled: false
  },
  PAYMENT_METHOD_SETUP: {
    next: 'INTEGRATION_SETUP',
    prev: 'PAYMENT_GATEWAY_SETUP',
    redirect: '/onboarding#integrations',
    onboardingCompletionRate: 83.33,
    backEnabled: true
  },
  PAYMENT_GATEWAY_SETUP: {
    next: 'PAYMENT_METHOD_SETUP',
    prev: 'CREDENTIALS_VERIFIED',
    redirect: '/onboarding#payment-methods',
    onboardingCompletionRate: 66.66,
    backEnabled: true
  },
  CREDENTIALS_VERIFIED: {
    next: 'PAYMENT_GATEWAY_SETUP',
    prev: 'ORGANIZATION_DETAIL_SETUP',
    redirect: '/onboarding#payment-gateways',
    onboardingCompletionRate: 50,
    backEnabled: false
  },
  ORGANIZATION_DETAIL_SETUP: {
    next: 'CREDENTIALS_VERIFIED',
    prev: 'DOMAIN_VERIFIED',
    redirect: '/onboarding#organization-details',
    onboardingCompletionRate: 40,
    backEnabled: false
  },
  DOMAIN_VERIFIED: {
    next: 'ORGANIZATION_DETAIL_SETUP',
    prev: 'IN_PROGRESS',
    redirect: '/onboarding#shop-settings',
    onboardingCompletionRate: 33.33,
    backEnabled: false
  },
  DISCONNECTED: {
    next: null,
    prev: null,
    redirect: '',
    onboardingCompletionRate: 0,
    backEnabled: false
  },
  FAILED: {
    next: null,
    prev: null,
    redirect: '',
    onboardingCompletionRate: 0,
    backEnabled: false
  },
  COMPLETED_WITH_ERRORS: {
    next: null,
    prev: 'PLATFORM_SELECTOR',
    redirect: '/onboarding#waitlist',
    onboardingCompletionRate: 83.33,
    backEnabled: false
  },
  DUPLICATE_DOMAIN: {
    next: null,
    prev: 'IN_PROGRESS',
    redirect: '/onboarding#duplicate-domain',
    onboardingCompletionRate: 0,
    backEnabled: false
  },
  PLATFORM_SELECTOR: {
    next: null,
    prev: 'IN_PROGRESS',
    redirect: '/onboarding#platform-select',
    onboardingCompletionRate: 83.33,
    backEnabled: true
  },
  PENDING: {
    next: null,
    prev: null,
    redirect: '',
    onboardingCompletionRate: 0,
    backEnabled: false
  }
}

/**
 * Returns active store from the list of stores
 *
 * @param  {OnboardingStatusType} status     Current status of onboarding
 *
 * @return  {OnboardingMapper}     Returns the next and prev steps for current onboarding steps
 */
export const getCurrentOnbaordingStatus = (status: OnboardingStatusType) => {
  if (!status) return

  return onboardingMapper[status]
}

/**
 * Returns mapping for onboarding steps
 *
 * @return  {PlatformsMapper}     Returns mapping
 */
export const platformMapper: PlatformsMapper = {
  BIG_COMMERCE: {
    name: 'Big Commerce',
    value: 'BIG_COMMERCE'
  },
  WOO_COMMERCE: {
    name: 'Woo Commerce',
    value: 'WOO_COMMERCE'
  },
  MAGENTO: {
    name: 'Magento',
    value: 'MAGENTO'
  },
  SQUARE: {
    name: 'Square',
    value: 'SQUARE'
  },
  OPEN_CART: {
    name: 'Open cart',
    value: 'OPEN_CART'
  },
  ECWID: {
    name: 'Ecwid',
    value: 'ECWID'
  },
  DUKAAN: {
    name: 'Dukaan',
    value: 'DUKAAN'
  },
  CUSTOM: {
    name: 'Custom',
    value: 'CUSTOM'
  },
  OTHER: {
    name: 'Other',
    value: 'OTHER'
  }
}

/**
 * Returns active store from the list of stores
 *
 * @param  {OnboardingStatusType} status     Current status of onboarding
 *
 * @return  {PlatformsMapper}     Returns the next and prev steps for current onboarding steps
 */
export const getPlatform = (platform: PlatformsType) => {
  if (!platform) return

  return platformMapper[platform]
}

/**
 * Returns GST registartion typ
 *
 * @return  {GSTRegistrationType}     Returns mapping
 */
export const gstRegistrationTypes: GSTRegistrationType[] = [
  {
    value: 'REGISTERED_BUSINESS_REGULAR',
    title: 'Registered business – Regular',
    subtitle: 'Business that is registered under GST'
  },
  {
    value: 'REGISTERED_BUSINESS_COMPOSITE',
    title: 'Registered business – Composition',
    subtitle: 'Business that is registered under the Composition Scheme in GST'
  },
  {
    value: 'UNREGISTERED_BUSINESS',
    title: 'Unregistered business',
    subtitle: 'Business that is unregistered'
  }
]

/**
 * Method to get Provinces/State Codes by Country Code
 *
 * @param  {string} countryCode          Country Code
 *
 */
export const getProvincesByCountryCode = async (countryCode: string) => {
  if (!countryCode) return []
  try {
    const response = await getRequest(`/countries/${countryCode}/provinces`, apiURI.PUBLIC_FLO_CHECKOUT)
    return response
  } catch (e) {
    console.error(e)
    return []
  }
}

/**
 * Method to get Provinces/State Codes by Country Code
 *
 * @param  {string} zip          Country Code
 *
 */
export const getDetailsByZip = async (zip: string) => {
  if (!Boolean(zip)) return {}
  try {
    const response = await getRequest(`/pincodes/${zip}`, apiURI.PUBLIC_FLO_CHECKOUT)
    return response?.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const businessDetailsConfig: any[] = [
  {
    key: 'tax_type',
    isEnabled: true,
    isMandatory: true
  },
  {
    key: 'identification_number',
    isEnabled: true,
    isMandatory: false
  },
  {
    key: 'business_legal_name',
    isEnabled: true,
    isMandatory: true
  },
  {
    key: 'business_trade_name',
    isEnabled: true,
    isMandatory: true
  },
  {
    key: 'gst_business_name',
    isEnabled: true,
    isMandatory: true
  },
  {
    key: 'billing_address1',
    isEnabled: true,
    isMandatory: true,
    isAddressField: true
  },
  {
    key: 'billing_address2',
    isEnabled: true,
    isMandatory: false,
    isAddressField: true
  },
  {
    key: 'billing_zip',
    isEnabled: true,
    isMandatory: true,
    isAddressField: true
  },
  {
    key: 'billing_city',
    isEnabled: true,
    isMandatory: true,
    isAddressField: true
  },
  {
    key: 'billing_state',
    isEnabled: true,
    isMandatory: true,
    isAddressField: true
  },
  {
    key: 'billing_state_code',
    isEnabled: true,
    isMandatory: true,
    isAddressField: true
  },
  {
    key: 'billing_country',
    isEnabled: true,
    isMandatory: true,
    isAddressField: true
  },
  {
    key: 'billing_country_code',
    isEnabled: true,
    isMandatory: true,
    isAddressField: true
  },
  {
    key: 'shipping_address1',
    isEnabled: true,
    isMandatory: true,
    isAddressField: true
  },
  {
    key: 'shipping_address2',
    isEnabled: true,
    isMandatory: false,
    isAddressField: true
  },
  {
    key: 'shipping_zip',
    isEnabled: true,
    isMandatory: true,
    isAddressField: true
  },
  {
    key: 'shipping_city',
    isEnabled: true,
    isMandatory: true,
    isAddressField: true
  },
  {
    key: 'shipping_state',
    isEnabled: true,
    isMandatory: true,
    isAddressField: true
  },
  {
    key: 'shipping_state_code',
    isEnabled: true,
    isMandatory: true,
    isAddressField: true
  },
  {
    key: 'shipping_country',
    isEnabled: true,
    isMandatory: true,
    isAddressField: true
  },
  {
    key: 'shipping_country_code',
    isEnabled: true,
    isMandatory: true,
    isAddressField: true
  },
  {
    key: 'poc_email_id',
    isEnabled: true,
    isMandatory: true
  },

  {
    key: 'poc_name',
    isEnabled: true,
    isMandatory: true
  },

  {
    key: 'poc_phone_number',
    isEnabled: true,
    isMandatory: true
  },
  {
    key: 'category',
    isEnabled: true,
    isMandatory: true
  }
]

/**
 * Takes address configuration and constructs address object with their respective properties
 *
 * @param  {Array[{}]} addressConfig         Configuration of various address fields
 *
 * @return {ValidationSchema}               Returns Validation Schema of the addresses
 */
export const getAddressFields = (addressConfig: string[], states: any) => {
  const validationSchema: ValidationSchema = {}
  if (!Boolean(addressConfig)) return validationSchema
  //TODO: Logic to be changed to BE for gst fields.
  addressConfig.forEach((field: any) => {
    if (field.isEnabled) {
      validationSchema[field.key] = getFieldProperties(field.key, field.isMandatory)
    }
  })
  return validationSchema
}

/**
 * Method takes field key and isMandatory, returns the properties of that field
 *
 * @param  {string} key               ID of the field
 * @param  {boolean} isMandatory      If the field is required or optional
 *
 * @return {object}                   Returns the field properties
 */
const getFieldProperties = (key: string, isMandatory: boolean) => {
  let property = {}
  switch (key) {
    case 'zip':
    case 'billing_zip':
    case 'shipping_zip': {
      property = {
        ...(Boolean(isMandatory) && {
          required: t('pincode_required')
        }),
        type: 'string',
        when: (value: string, values: any) => {
          if (values.country_code === 'IN' && (value.length !== 6 || !isNumeric(value))) {
            return {
              status: true,
              message: t('valid_pincode')
            }
          }
        },
        formatters: [formatZip]
      }
      break
    }
    case 'name': {
      property = {
        type: 'string',
        required: t('name_required'),
        min: {
          limit: 3,
          message: t('valid_name')
        },
        max: {
          limit: 450,
          message: t('valid_name')
        },
        onlyLettersAndPeriod: t('valid_name'),
        formatters: ['LETTERS_AND_PERIOD']
      }
      break
    }
    case 'city':
    case 'billing_city':
    case 'shipping_city': {
      property = {
        type: 'string',
        ...(Boolean(isMandatory) && {
          required: t('city_required')
        }),
        formatters: ['CAPITALIZE', 'LETTERS_ONLY'],
        onlyLetters: t('valid_city')
      }
      break
    }
    case 'state':
    case 'billing_state':
    case 'shipping_state': {
      property = {
        type: 'string',
        ...(Boolean(isMandatory) && {
          required: t('state_required')
        }),
        formatters: ['CAPITALIZE', 'LETTERS_ONLY']
      }
      break
    }
    case 'state_code':
    case 'billing_state_code':
    case 'shipping_state_code': {
      property = {
        type: 'string',
        ...(Boolean(isMandatory) && {
          required: t('state_required')
        }),
        notSpecialCharactersOrNumbersOnly: t('valid_state'),
        formatters: ['CAPITALIZE', 'LETTERS_ONLY']
      }
      break
    }
    case 'country':
    case 'billing_country':
    case 'shipping_country': {
      property = {
        ...(Boolean(isMandatory) && {
          required: t('valid_country')
        }),
        type: 'string',
        onlyLetters: t('valid_country'),
        formatters: ['CAPITALIZE']
      }
      break
    }
    case 'country_code': {
      property = {
        ...(Boolean(isMandatory) && {
          required: t('country_code_required')
        }),
        type: 'string'
      }
      break
    }
    case 'type': {
      property = {
        required: t('address_type_required')
      }
      break
    }
    case 'email': {
      property = {
        type: 'string',
        ...(Boolean(isMandatory) && {
          required: t('email_required'),
          email: t('invalid_email')
        }),
        formatters: ['FIRST_CHAR_LOWERCASE', 'LETTERS_WITH_NO_SPACE']
      }
      break
    }
    case 'gstin':
    case 'identification_number': {
      property = {
        type: 'string',
        formatters: ['UPPERCASE', 'ALPHA_NUMERIC'],
        ...(Boolean(isMandatory) && {
          required: t('valid_gstin')
        })
      }

      break
    }
    case 'gst_business_name': {
      property = {
        type: 'string',
        ...(Boolean(isMandatory) && {
          required: t('gst_business_name_required')
        })
      }
      break
    }
    case 'business_legal_name': {
      property = {
        type: 'string',
        ...(Boolean(isMandatory) && {
          required: t('business_legal_name_required')
        })
      }
      break
    }
    case 'business_trade_name': {
      property = {
        type: 'string',
        ...(Boolean(isMandatory) && {
          required: t('business_trade_name_required')
        })
      }
      break
    }
    case 'tax_type': {
      property = {
        type: 'string',
        ...(Boolean(isMandatory) && {
          required: t('tax_type_required')
        })
      }
      break
    }
    case 'category': {
      property = {
        type: 'string',
        ...(Boolean(isMandatory) && {
          required: t('category_required')
        })
      }
      break
    }
    case 'address1':
    case 'billing_address1':
    case 'shipping_address1': {
      property = {
        type: 'string',
        ...(Boolean(isMandatory) && {
          required: t('full_address'),
          notSpecialCharactersOrNumbersOnly: t('valid_address'),
          min: {
            limit: 12,
            message: t('full_address')
          }
        })
      }
      break
    }
    case 'address2':
    case 'billing_address2':
    case 'shipping_address2': {
      property = {
        type: 'string',
        ...(Boolean(isMandatory) && {
          required: t('full_address'),
          notSpecialCharactersOrNumbersOnly: t('valid_address'),
          min: {
            limit: 1,
            message: t('full_address')
          }
        })
      }
      break
    }
    case 'poc_name': {
      property = {
        type: 'string',
        ...(Boolean(isMandatory) && {
          required: t('poc_name_required')
        })
      }
      break
    }
    case 'poc_email_id': {
      property = {
        type: 'string',
        ...(Boolean(isMandatory) && {
          required: t('invalid_email'),
          email: t('invalid_email')
        }),
        formatters: ['FIRST_CHAR_LOWERCASE', 'LETTERS_WITH_NO_SPACE']
      }
      break
    }
    case 'poc_phone_number': {
      property = {
        type: 'string',
        ...(Boolean(isMandatory) && {
          required: t('invalid_phone_number'),
          phone: t('invalid_phone_number'),
          formatters: ['PHONE']
        })
      }
      break
    }
  }

  return property
}
